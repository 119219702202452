<template>
  <div class="main relative h-auto w-full">
    <slot></slot>
  </div>
</template>

<script lang="ts"></script>

<style lang="scss">
body {
  background: white;
}

a.link-primary {
  @apply text-primary;
  &:hover {
    @apply text-primary-dark;
  }
  &:active {
    @apply text-primary-dark;
  }
  &:focus {
    @apply text-primary-dark;
  }
  &:visited {
    @apply text-primary-dark;
  }
}
</style>
